<template>
	<transition name="el-fade-in-linear">
		<div v-show="showNav" class="my-header hidden-xs-only">
			<el-menu :default-active="activeIndex" mode="horizontal" :ellipsis="false" @select="handleSelect">
				<el-menu-item index="#anchor1">LOGO</el-menu-item>
				<div class="my-header__flex-grow" />
				<el-menu-item index="#anchor1">主页</el-menu-item>
				<el-menu-item index="#anchor2">关于</el-menu-item>
				<el-menu-item index="#anchor3">联系</el-menu-item>
			</el-menu>
		</div>
	</transition>
	<div id="#anchor1" class="my-home">
		<el-row class="my-home__row" justify="center" align="middle">
			<el-col :xl="16">
				<el-row justify="center" align="middle">
					<el-col :md="24" :lg="10" class="my-home__profile">
						<el-image class="my-home__profile-img" :src="profileImg" fit="cover" />
					</el-col>
					<el-col :xs="22" :xs-offset="1" :md="20" :md-offset="2" :lg="14">
						<h1 class="my-home__banner">你好，我是 <span>老猿</span> 。</h1>
						<div class="my-home__banner-text hidden-xs-only">
							<vuetyped :strings="['天行健，君子以自强不息。 ——《周易》']" :loop="true" :typeSpeed="100"
								:smart-backspace="true">
								<div class="typing" />
							</vuetyped>
						</div>
						<el-divider />
						<h3>这是一个个人网站，主要用于个人学习、研究及技术验证。</h3>
						<h5>本站技术：</h5>
						<ul>
							<li>Vue3</li>
							<li>Element Plus</li>
							<li>Echarts</li>
							<li>vue3typed</li>
						</ul>
					</el-col>
				</el-row>
			</el-col>
		</el-row>
	</div>
	<div id="#anchor2" class="my-about">
		<div class="my-title">About Me</div>
		<el-row :gutter="40" justify="center">
			<el-col :xs="24" :sm="8">
				<div class="my-about__card">
					<el-card header="档案" shadow="hover">
						<el-timeline>
							<el-timeline-item timestamp="姓名：" placement="top">张三</el-timeline-item>
							<el-timeline-item timestamp="性别：" placement="top">男</el-timeline-item>
							<el-timeline-item timestamp="年龄：" placement="top">999</el-timeline-item>
							<el-timeline-item timestamp="称号：" placement="top">法外狂徒</el-timeline-item>
							<el-timeline-item timestamp="职业：" placement="top">山长</el-timeline-item>
							<el-timeline-item timestamp="简介：" placement="top">暂无</el-timeline-item>
						</el-timeline>
					</el-card>
				</div>
			</el-col>
			<el-col :xs="24" :sm="8">
				<div class="my-about__card">
					<el-card header="能力" shadow="hover">
						<div id="myEcharts"></div>
					</el-card>
				</div>
			</el-col>
		</el-row>
	</div>
	<div id="#anchor3" class="my-contact">
		<div class="my-title">Contact Me</div>
		<el-row :gutter="40" justify="center">
			<el-col :xs="20" :sm="12">
				<el-row class="my-contact__item">
					<el-col :sm="6" class="my-contact__item-label">
						邮箱：
					</el-col>
					<el-col :sm="18" class="my-contact__item-text">
						985281504@qq.com
					</el-col>
				</el-row>
				<el-row class="my-contact__item">
					<el-col :sm="6" class="my-contact__item-label">
						地址：
					</el-col>
					<el-col :sm="18" class="my-contact__item-text">
						本宇宙·双鱼-鲸鱼座超星系团复合体·拉尼亚凯亚超星系团·室女座超星系团·本星系群·银河系·猎户臂·古尔德带·本地泡·本星际云·太阳系·内太阳系·地月系·地球·中国·昆明
					</el-col>
				</el-row>
			</el-col>
		</el-row>
	</div>
	<div class="my-footer">
		<div class="my-footer__item">
			<a class="my-footer__link" target="_blank" href="https://yj729.com">
				<p> Copyright © 2022-2024 yj729.com </p>
			</a>
		</div>
		<div class="my-footer__item">
			<a class="my-footer__link" target="_blank" href="https://beian.miit.gov.cn/">
				<p> 滇ICP备2022003935号-1 </p>
			</a>
		</div>
		<div class="my-footer__item">
			<a class="my-footer__link" target="_blank"
				href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=53010202001492">
				<img src="beian.png" />
				<p> 滇公网安备53010202001492号 </p>
			</a>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts'
	import profileUrl from '@/assets/profile.jpg'
	export default {
		name: 'App',
		components: {},
		data() {
			return {
				windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
				windowHeight: document.documentElement.clientHeight, //实时屏幕高度 
				showNav: false,
				activeIndex: '#anchor0',
				anchor1: 0,
				anchor2: 0,
				anchor3: 0,
				profileImg: profileUrl
			}
		},
		mounted() {
			this.initEcharts();
			window.addEventListener('scroll', this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
			this.anchor1 = document.getElementById('#anchor1').offsetTop;
			this.anchor2 = document.getElementById('#anchor2').offsetTop;
			this.anchor3 = document.getElementById('#anchor3').offsetTop;
		},
		methods: {
			handleScroll() {
				// 滚动条偏移量 
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop ||
					document.body.scrollTop;
				// 显示导航栏
				this.showNav = (scrollTop >= this.windowHeight / 2);
				// 同步菜单选中
				if (scrollTop >= this.anchor1 && scrollTop < this.anchor2) this.activeIndex = '#anchor1';
				else if (scrollTop >= this.anchor2 && scrollTop < this.anchor3) this.activeIndex = '#anchor2';
				else if (scrollTop >= this.anchor3) this.activeIndex = '#anchor3';
			},
			handleSelect(key) {
				document.documentElement.scrollTop = document.getElementById(key).offsetTop;
			},
			initEcharts() {
				// // 基于准备好的dom，初始化echarts实例
				let myChart = echarts.init(document.getElementById('myEcharts'));
				// 绘制图表
				myChart.setOption({
					radar: {
						indicator: [{
								name: '德',
								max: 100
							},
							{
								name: '智',
								max: 100
							},
							{
								name: '体',
								max: 100
							},
							{
								name: '美',
								max: 100
							},
							{
								name: '劳',
								max: 100
							}
						]
					},
					series: [{
						type: 'radar',
						data: [{
							name: '能力',
							value: [100, 100, 100, 100, 100]
						}]
					}]
				});
			}
		}
	}
</script>

<style>
	html,
	body {
		margin: 0;
		padding: 0;
		overflow-x: hidden;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #303133;
	}

	#myEcharts {
		width: 100%;
		height: 300px;
	}

	.my-title {
		font-size: 3rem;
		margin-bottom: 3rem;
		text-align: center;
	}

	.my-header {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 999;
		box-sizing: border-box;
		flex-shrink: 0;
		height: 60px;
		border-bottom: solid 1px var(--el-menu-border-color);
	}

	.my-header .el-menu--horizontal {
		border-bottom: none;
	}

	.my-header__flex-grow {
		flex-grow: 1;
	}

	.my-home {
		width: 100%;
		height: 100vh;
		background-color: #409EFF;
	}

	.my-home__row {
		height: 100vh;
	}

	.my-home__profile {
		text-align: center;
	}

	.my-home__profile-img {
		width: 256px;
		height: 256px;
		border-radius: 128px;
	}

	.my-home__banner {
		text-align: left;
	}

	.my-home__banner span {
		font-size: 200%;
	}

	.my-home__banner-text {
		display: inline-block;
	}

	.my-about {
		width: 100%;
		margin-bottom: 8rem;
	}

	.my-about .my-title {
		margin-top: 8rem;
	}

	.my-about__card {
		padding: 0 .5rem 1rem;
	}

	.my-contact {
		width: 100%;
		margin-bottom: 8rem;
	}

	.my-contact__item {
		margin-bottom: 1rem;
	}

	.my-contact__item-label {
		font-weight: bolder;
		margin-bottom: 1rem;
	}

	.my-contact__item-text {
		text-align: left;
		margin-bottom: 1rem;
	}

	.my-footer {
		width: 100%;
		padding: 4rem 0 4rem;
		background-color: #606266;
		color: #C0C4CC;
		text-align: center;
	}

	.my-footer__item {
		width: 300px;
		margin: 0 auto;
		padding: 20px 0;
	}

	.my-footer__link {
		display: inline-block;
		text-decoration: none;
		height: 20px;
		line-height: 20px;
	}

	.my-footer__link img {
		float: left;
	}

	.my-footer__link p {
		float: left;
		height: 20px;
		line-height: 20px;
		margin: 0px 0px 0px 5px;
		color: #939393;
	}
</style>
